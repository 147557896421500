<template>
  <div class="NewsDesc">
    <Banner
      tit1="行业资讯"
      tit2="Industry information"
      url="4"
      back="true"
    ></Banner>

    <div class="Notice">
      <el-card class="box_card">
        <h2>{{ news.name }}</h2>
        <p class="date-box">{{ news.push_date }}</p>
        <div class="ql-container ql-snow">
          <div class="ql-editor news-desc" v-html="news.content"></div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import Banner from './layout/Banner'
export default {
  data() {
    return {
      news: [],
    }
  },
  components: { Banner },
  created() {
    this.init()
  },
  methods: {
    init() {
      let url = ''
      if (this.$route.query.type == 1) {
        url = 'articleInfoNotices/'
      } else {
        url = 'articleInfoNEws/'
      }
      let _this = this
      _this.$http
        .get(process.env.VUE_APP_URL + url + _this.$route.query.id)
        .then(data => {
          if (data.data.code == 200) {
            _this.news = data.data.data
          } else {
            _this.$message.error(data.data.msg)
          }
        })
        .catch(err => {
          _this.$message.error('网络错误，请重试')
          console.log(err)
        })
    },
  },
}
</script>
<style lang="less" scoped>
.NewsDesc {
  .Notice {
    width: 100%;
    background-size: cover;
    padding: 40px 0px;
    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #7c8087;
      line-height: 20px;
      margin-top: 10px;
    }
    .el-card {
      width: 85vw;
      margin: 0px auto;
    }
    h2 {
      font-size: 18px;
      color: #6f0c08;
      text-align: center;
    }
    .date-box {
      padding: 10px;
      text-align: center;
    }
    .news-desc {
      width: 100%;
    }
    img.cont_img {
      width: 100%;
    }
  }
}
</style>
